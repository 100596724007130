<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
<!--            <div class="w-100 alert-danger p-3"><div class="text-center text-dark font-3xl">Программа работает в бесплатном режиме, функциональность ограничена</div></div>-->
            <transition name="fade">
              <router-view />
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar.vue';
import TheHeader from './TheHeader.vue';
import TheFooter from './TheFooter.vue';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  mounted() {
    if (this.$route.path === '/admin/') {
      this.$router.push({name: 'admin.reestrs.list'});
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
