<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pb-0 pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <CIcon
            name="cil-user"
            size="xl"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem>
      <CIcon name="cil-account-logout" />
      <router-link :to="{ name: 'admin.logout' }">
        Выйти
      </router-link>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
};
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
