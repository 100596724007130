<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      :to="{ name: $store.getters.isServiceAdmin ? 'admin.reestrs.list' : 'admin.reestrs.list' }"
    >
      <CIcon
        class="c-sidebar-brand-full"
        name="logo-full"
        size="custom-size"
        :height="45"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo-minimized"
        size="custom-size"
        :height="40"
      />
    </CSidebarBrand>

    <CRenderFunction
      flat
      :content-to-render="nav"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
export default {
  name: 'TheSidebar',
  computed: {
    nav() {
      let children1 = [
        {
          _name: 'CSidebarNavItem',
          icon: 'cil-list',
          name: 'Реестры',
          to: '/admin/reestrs/',
        },
        {
          _name: 'CSidebarNavItem',
          icon: 'cil-cart',
          name: 'Чеки',
          to: '/admin/orders/',
        },
      ];

      let children = children1;
      if (this.$store.getters.isServiceAdmin) {
        children.push(
          {
            _name: 'CSidebarNavItem',
            icon: 'cil-settings',
            name: 'Настройки',
            to: '/admin/settings/',
          },
        );
      }

      return [
        {
          _name: 'CSidebarNav',
          _children: children,
        },
      ];
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
