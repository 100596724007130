<template>
  <CFooter :fixed="false">
    <div>
      Онлайн-кассир
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Разработано в</span>
      <a
        href="https://inlb.ru"
        target="_blank"
      >ЛИ</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
